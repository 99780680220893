import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { APP_TOKEN } from '../../../constants/widget';
import { getPageData } from '../../../editor/editor-sdk-wrappers/pages';
import {
  getMembersAreaPageRef,
  getSettingsPageRef,
} from '../../../editor/services/page';

export const getProfilePageId = async (editorSDK: FlowEditorSDK) => {
  const profilePageRef = await getMembersAreaPageRef(editorSDK);
  const profilePage = await editorSDK.pages.data.get(APP_TOKEN, {
    pageRef: profilePageRef,
  });

  if (!profilePage?.id) {
    throw new Error('Members Area Profile page id is not found');
  }

  return profilePage.id;
};

export const getSettingsPageId = async (editorSDK: FlowEditorSDK) => {
  const settingsPageRef = await getSettingsPageRef(editorSDK);
  const settingsPage = await editorSDK.pages.data.get(APP_TOKEN, {
    pageRef: settingsPageRef,
  });

  if (!settingsPage?.id) {
    throw new Error('Members Area Settings page id is not found');
  }

  return settingsPage.id;
};

export const getProfilePageUriSEO = async (editorSDK: FlowEditorSDK) => {
  const pageRef = await getMembersAreaPageRef(editorSDK);
  const { pageUriSEO } = await getPageData(editorSDK, pageRef);

  return pageUriSEO;
};

export const getSettingsPageUriSEO = async (editorSDK: FlowEditorSDK) => {
  const pageRef = await getSettingsPageRef(editorSDK);
  const { pageUriSEO } = await getPageData(editorSDK, pageRef);

  return pageUriSEO;
};
