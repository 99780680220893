import type { WidgetId } from '@wix/members-area-app-definitions';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { getMembersAreaUmbrellaPublicAPI } from '../../../editor/services/members-editor-script';
import type { AddedWidgetConfig, RouteConfiguration } from '../../../types';

export const getProfilePageRoutes = async (editorSDK: FlowEditorSDK) => {
  const membersAreaPublicAPI = await getMembersAreaUmbrellaPublicAPI(editorSDK);
  return membersAreaPublicAPI.getRoutes();
};

const setProfilePageRoutes = async (
  editorSDK: FlowEditorSDK,
  routes: RouteConfiguration[],
) => {
  const membersAreaPublicAPI = await getMembersAreaUmbrellaPublicAPI(editorSDK);
  return membersAreaPublicAPI.setRoutes(routes);
};

const getIsRouteAlreadyAdded = (
  currentRoutes: RouteConfiguration[] | undefined,
  newPath: string,
  newState: string,
) => {
  const routes = currentRoutes ?? [];
  const routesExists = routes.some(({ path, state }) => {
    return path === newPath || state === newState;
  });

  return routesExists;
};

const getUpdatedRoutes = (
  currentRoutes: RouteConfiguration[] | undefined,
  addedWidgetsConfigs: AddedWidgetConfig[],
) => {
  return addedWidgetsConfigs.reduce<RouteConfiguration[]>(
    (previousValue, currentValue) => {
      if (!currentValue.state) {
        return previousValue;
      }

      const isRouteAlreadyAdded = getIsRouteAlreadyAdded(
        currentRoutes,
        currentValue.path,
        currentValue.state,
      );

      if (isRouteAlreadyAdded) {
        return previousValue;
      }

      previousValue.push({
        path: currentValue.path,
        state: currentValue.state,
        vfr: currentValue.vfr,
        home: currentValue.home,
        private: currentValue.private,
        widgetId: currentValue.widgetId,
      });

      return previousValue;
    },
    currentRoutes ?? [],
  );
};

export const updateProfilePageRoutes = async (
  editorSDK: FlowEditorSDK,
  addedWidgetsConfigs: AddedWidgetConfig[],
) => {
  const currentRoutes = await getProfilePageRoutes(editorSDK);
  const updatedRoutes = getUpdatedRoutes(currentRoutes, addedWidgetsConfigs);
  await setProfilePageRoutes(editorSDK, updatedRoutes);
};

export const getSettingsPageRoutes = async (editorSDK: FlowEditorSDK) => {
  const membersAreaPublicAPI = await getMembersAreaUmbrellaPublicAPI(editorSDK);
  return membersAreaPublicAPI.getSettingsRoutes();
};

const setSettingsPageRoutes = async (
  editorSDK: FlowEditorSDK,
  routes: RouteConfiguration[],
) => {
  const membersAreaPublicAPI = await getMembersAreaUmbrellaPublicAPI(editorSDK);
  return membersAreaPublicAPI.setSettingsRoutes(routes);
};

export const updateSettingsPageRoutes = async (
  editorSDK: FlowEditorSDK,
  addedWidgetsConfigs: AddedWidgetConfig[],
) => {
  const currentRoutes = await getSettingsPageRoutes(editorSDK);
  const updatedRoutes = getUpdatedRoutes(currentRoutes, addedWidgetsConfigs);
  await setSettingsPageRoutes(editorSDK, updatedRoutes);
};

export const removeRoutesFromSettingsPage = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  const routes = await getSettingsPageRoutes(editorSDK);
  if (!routes.length) {
    return;
  }

  const filteredRoutes = routes.filter(
    (route) => !widgetsIds.includes(route.widgetId),
  );

  return setSettingsPageRoutes(editorSDK, filteredRoutes);
};

export const removeRoutesFromProfilePage = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  const routes = await getProfilePageRoutes(editorSDK);
  if (!routes.length) {
    return;
  }

  const filteredRoutes = routes.filter(
    (route) => !widgetsIds.includes(route.widgetId),
  );

  return setProfilePageRoutes(editorSDK, filteredRoutes);
};
