import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

type SetControllerControllerConfigProps = {
  editorSDK: FlowEditorSDK;
  controllerRef: ComponentRef;
  key: 'routes';
  value: object;
};

export const setControllerConfigProp = async ({
  editorSDK,
  controllerRef,
  key,
  value,
}: SetControllerControllerConfigProps) => {
  const { config } = await editorSDK.controllers.getData('', {
    controllerRef,
    scope: 'APP',
  });

  await editorSDK.controllers.saveConfiguration('', {
    controllerRef,
    scope: 'APP',
    config: { ...config, [key]: value },
  });
};
