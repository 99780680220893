import type { TranslateFunc } from '@wix/bob-widget-services';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import type { FlowEditorSDK, GetAppManifestFn } from '@wix/yoshi-flow-editor';

import { withRetry } from '../../editor/services';
import { toMonitored } from '../../editor/services/monitor';

type FetchWidgetsStageDataOptions = {
  initialAppData: any; // issue on EP: https://jira.wixpress.com/browse/EP-4024
  editorSDK: FlowEditorSDK;
  translateFunc: TranslateFunc;
};

const monitoredFetchWidgetsStageData = (
  options: FetchWidgetsStageDataOptions,
) => {
  const action = () => {
    return withRetry(() => fetchWidgetsStageData(options), {
      methodName: 'ma-split.fetchWidgetsStageData',
      retryCount: 20,
      retryDelay: 400,
    });
  };
  return toMonitored('ma-split.fetchWidgetsStageData', action);
};

export const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  _contextParams,
  flowAPI,
) => {
  const { appManifestBuilder } = options;
  const { t } = flowAPI.translations;

  const rawManifest = await monitoredFetchWidgetsStageData({
    editorSDK,
    translateFunc: t,
    initialAppData: options,
  });

  return appManifestBuilder.withJsonManifest(rawManifest).build();
};
