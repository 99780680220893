import type {
  EditorReadyFn,
  EditorScriptFlowAPI,
  FlowEditorSDK,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';

import { globalAppState } from './editor/services';
import { initializeMonitoring } from './editor/services/monitor';
import {
  exportsFn,
  shouldUseSplitPlatformApp,
  singlePageApp,
  splitApp,
} from './editor-platform';

const getEditorPlatformApp = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  options?: Partial<{ firstInstall: boolean }>,
) => {
  const useSplitPlatformApp = await shouldUseSplitPlatformApp(
    editorSDK,
    flowAPI,
    options,
  );

  return useSplitPlatformApp ? splitApp : singlePageApp;
};

const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  options,
  flowAPI,
) => {
  globalAppState.setFlowAPI(flowAPI);
  globalAppState.setIsClassicEditor(options.origin.type === 'CLASSIC');
  initializeMonitoring(flowAPI, options);

  const platformApp = await getEditorPlatformApp(editorSDK, flowAPI, options);
  await platformApp.editorReady(editorSDK, appDefinitionId, options, flowAPI);
};

const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  contextParams,
  flowAPI,
) => {
  const platformApp = await getEditorPlatformApp(
    editorSDK,
    flowAPI,
    contextParams,
  );

  return platformApp.getAppManifest(options, editorSDK, contextParams, flowAPI);
};

export { editorReady, exportsFn as exports, getAppManifest };
