import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { ElementId } from '../../constants';
import { waitForMemberPageController } from '../controller/member-page-controller';
import { getComponentSerializedData } from '../editor-sdk-wrappers';
import { getProfilePageBobWidgetRef } from './page-ref';

type WidgetIdToStateMap = Record<string, string>;

export const getMultiStateBoxRef = async (editorSDK: FlowEditorSDK) => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const controllerRef = await waitForMemberPageController(editorSDK, widgetRef);
  const [componentRef] = await editorSDK.components.findAllByRole('', {
    controllerRef,
    role: ElementId.AppsContainer,
  });

  if (!componentRef) {
    throw new Error('Could not find MultiStateBox component');
  }

  return componentRef;
};

const getMultiStateBoxSerializedData = async (editorSDK: FlowEditorSDK) => {
  const componentRef = await getMultiStateBoxRef(editorSDK);
  const serializedData = await getComponentSerializedData(
    editorSDK,
    componentRef,
  );

  if (!serializedData?.components?.length) {
    throw new Error('Could not get MultiStateBox component serialized data');
  }

  return serializedData;
};

export const getMultiStateBoxStatesWidgetsIdsToStateMap = async (
  editorSDK: FlowEditorSDK,
): Promise<WidgetIdToStateMap> => {
  const multiStateBoxSerializedData = await getMultiStateBoxSerializedData(
    editorSDK,
  );

  return multiStateBoxSerializedData.components!.reduce<WidgetIdToStateMap>(
    (previousValue, currentValue) => {
      if (
        currentValue?.components?.[0]?.slots?.slots?.slot?.data?.widgetId &&
        currentValue?.connections?.items?.[0]?.role
      ) {
        return {
          ...previousValue,
          [currentValue.components[0].slots.slots.slot.data.widgetId]:
            currentValue.connections.items[0].role,
        };
      }

      return previousValue;
    },
    {},
  );
};
