import type { WidgetId } from '@wix/members-area-app-definitions';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { Experiment } from '../../constants';
import { refreshApp } from '../editor-sdk-wrappers';
import { globalAppState } from './global-app-state';
import {
  removeLoginBarIconsMenuItems,
  updateLoginBarIconsMenuMembersPath,
} from './login-bar-icons-menu';
import {
  removeLoginBarItems,
  updateLoginBarLabel,
  updateLoginBarMembersPath,
} from './login-bar-menu';
import {
  removeMembersSubMenuItems,
  updateMembersSubMenuItemLabel,
  updateMembersSubMenuMembersPath,
} from './members-sub-menu';

export const removeWidgetsMenusItems = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  return Promise.all([
    removeMembersSubMenuItems(editorSDK, widgetsIds),
    removeLoginBarItems(editorSDK, widgetsIds),
    removeLoginBarIconsMenuItems(editorSDK, widgetsIds),
  ]);
};

export const updateMenusItemLabel = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
  newLabel: string,
) => {
  const flowAPI = globalAppState.getFlowAPI();
  const shouldRefreshSubMenuLabel = flowAPI?.experiments.enabled(
    Experiment.VerticalDeletionRemoveRefreshApp,
  );

  await Promise.all([
    updateMembersSubMenuItemLabel(editorSDK, widgetId, newLabel),
    updateLoginBarLabel(editorSDK, widgetId, newLabel),
  ]);

  if (shouldRefreshSubMenuLabel) {
    // need to refresh for sub menu label update
    return refreshApp(editorSDK);
  }
};

export const updateMenusMembersPath = async (
  editorSDK: FlowEditorSDK,
  currentPageUriSEO: string,
  newPageUriSEO: string,
) => {
  return Promise.all([
    updateMembersSubMenuMembersPath(
      editorSDK,
      currentPageUriSEO,
      newPageUriSEO,
    ),
    updateLoginBarMembersPath(editorSDK, currentPageUriSEO, newPageUriSEO),
    updateLoginBarIconsMenuMembersPath(
      editorSDK,
      currentPageUriSEO,
      newPageUriSEO,
    ),
  ]);
};
