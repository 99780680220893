import type { WidgetId } from '@wix/members-area-app-definitions';

type GetSyncActionsProps = {
  sourceOfTruth: WidgetId[];
  syncTarget: WidgetId[];
};

export const determineSyncOperations = ({
  sourceOfTruth,
  syncTarget,
}: GetSyncActionsProps) => {
  const idsToAdd = sourceOfTruth.filter((id) => !syncTarget.includes(id));
  const idsToRemove = syncTarget.filter((id) => !sourceOfTruth.includes(id));
  return { idsToAdd, idsToRemove };
};
