import type { ContextParams } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { globalAppState } from '../../editor/services';
import type { MembersPublicAPI } from '../../types';
import { singlePageApp } from '../single-page-app';
import { shouldUseSplitPlatformApp, splitApp } from '../split-app';

const initPublicAPI = async (
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
) => {
  const flowAPI = globalAppState.getFlowAPI();
  let publicAPIFactory = singlePageApp.exports.public;

  if (flowAPI) {
    const useSplitPlatformApp = await shouldUseSplitPlatformApp(
      editorSDK,
      flowAPI,
      contextParams,
    );

    publicAPIFactory = useSplitPlatformApp
      ? splitApp.exports.public
      : singlePageApp.exports.public;
  }

  return publicAPIFactory(editorSDK, contextParams);
};

export const createPublicAPI = (
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
): MembersPublicAPI => {
  const getPublicAPI = () => initPublicAPI(editorSDK, contextParams);

  return {
    getMembersAreaPageRef: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMembersAreaPageRef();
    },
    getInstalledWidgetPlugins: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getInstalledWidgetPlugins();
    },
    addWidgetsPlugins: async (integrationApplication, shouldNavigate) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addWidgetsPlugins(
        integrationApplication,
        shouldNavigate,
      );
    },
    addLightboxes: async (lightboxes) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addLightboxes(lightboxes);
    },
    removeWidgets: async (widgetsIds) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.removeWidgets(widgetsIds);
    },
    updatePageUriSEO: async (pageUriSEO) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.updatePageUriSEO(pageUriSEO);
    },
    isProfilePageBobValid: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.isProfilePageBobValid();
    },
    navigateToSection: async (payload) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.navigateToSection(payload);
    },
    uninstall: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.uninstall();
    },
    refreshApp: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshApp();
    },
    openManageAndNavigatePanel: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.openManageAndNavigatePanel();
    },
    hideProfileCardContainer: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.hideProfileCardContainer();
    },
    /** @deprecated Routes are stored in the Umbrella application */
    getPublicApplications: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getPublicApplications();
    },
    /** @deprecated Routes are stored in the Umbrella application */
    getRoutes: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getRoutes();
    },
    /** @deprecated Routes are stored in the Umbrella application */
    updateRoute: async (payload) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.updateRoute(payload);
    },
    /** @deprecated Routes are stored in the Umbrella application */
    populateGlobalControllerWithRoutes: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.populateGlobalControllerWithRoutes();
    },
    /** @deprecated Profile Card visibility is toggled via Display panel of the widget */
    showProfileCard: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.showProfileCard();
    },
    /** @deprecated Profile Card visibility is toggled via Display panel of the widget */
    hideProfileCard: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.hideProfileCard();
    },
    /** @deprecated In Split version we do not need to change name of the page */
    renameMembersAreaPage: async (newPageTitle) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.renameMembersAreaPage(newPageTitle);
    },
    /** @deprecated This method is only for V2 and templates migration */
    setSidebarLayout: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setSidebarLayout();
    },
  };
};
