import type { ContextParams } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { globalAppState } from '../../editor/services';
import type { PrivateAPI } from '../../types';
import { singlePageApp } from '../single-page-app';
import { shouldUseSplitPlatformApp, splitApp } from '../split-app';

const initPrivateAPI = async (
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
) => {
  const flowAPI = globalAppState.getFlowAPI();
  let privateAPIFactory = singlePageApp.exports.private;

  if (flowAPI) {
    const useSplitPlatformApp = await shouldUseSplitPlatformApp(
      editorSDK,
      flowAPI,
      contextParams,
    );

    privateAPIFactory = useSplitPlatformApp
      ? splitApp.exports.private
      : singlePageApp.exports.private;
  }

  return privateAPIFactory(editorSDK, contextParams);
};

export const createPrivateAPI = (
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
): PrivateAPI => {
  const getPrivateAPI = () => initPrivateAPI(editorSDK, contextParams);

  return {
    getLayoutPreset: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getLayoutPreset();
    },
    setLayoutPreset: async (layoutPreset) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.setLayoutPreset(layoutPreset);
    },
    getSettingsPageLayoutPreset: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getSettingsPageLayoutPreset();
    },
    setSettingsPageLayoutPreset: async (layoutPreset) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.setSettingsPageLayoutPreset(layoutPreset);
    },
    updateMenusItemsOrder: async (props) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updateMenusItemsOrder(props);
    },

    updateMenusItemLabel: async (widgetId, newLabel) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updateMenusItemLabel(widgetId, newLabel);
    },
    openMemberSettingsBM: async (referralInfo) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.openMemberSettingsBM(referralInfo);
    },
  };
};
