import { EventType } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { EDITOR_ACTION } from '../../../../constants/editor-actions';
import { openUnifiedManagerPanel } from '../../panels/open-panels';

export const addEditorActionsListeners = async (editorSDK: FlowEditorSDK) => {
  await editorSDK.addEventListener(
    EventType.navBarMainActionClicked,
    async (event) => {
      const { actionId } = event.detail;

      if (actionId === EDITOR_ACTION.OPEN_UNIFIED_MANAGER_PANEL) {
        return openUnifiedManagerPanel(editorSDK, {
          initialTab: 'memberPageTabs',
        });
      } else if (
        actionId === EDITOR_ACTION.OPEN_SETTINGS_PAGE_UNIFIED_MANAGER_PANEL
      ) {
        return openUnifiedManagerPanel(editorSDK, {
          initialTab: 'settingsPageTabs',
        });
      }
    },
  );
};
