import type {
  IntegrationApplication,
  MembersAreaAppId,
} from '@wix/members-area-app-definitions';
import { getAppDefinitions } from '@wix/members-area-app-definitions/get-app-definitions';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import {
  MY_ACCOUNT_DEFINITION,
  SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE,
} from '../../constants';
import type { RouteConfiguration, WidgetSlot } from '../../types';

type ConstructRoutesProps = {
  pluginInfo: WidgetSlot['pluginInfo'];
  role: WidgetSlot['role'];
  appDefinitions: IntegrationApplication[];
};

const getMembersAreaApplicationsFromSlots = async (slots: WidgetSlot[]) => {
  return slots
    .map<MembersAreaAppId>(({ pluginInfo }) => ({
      appDefinitionId: pluginInfo!.appDefinitionId,
      widgetId: pluginInfo?.widgetId,
    }))
    .filter(({ appDefinitionId }) => {
      return appDefinitionId !== MY_ACCOUNT_DEFINITION.appDefinitionId;
    });
};

const constructRoute = ({
  pluginInfo,
  role,
  appDefinitions,
}: ConstructRoutesProps) => {
  const widgetId = pluginInfo!.widgetId;
  const appDefinition = appDefinitions.find((app) => app.widgetId === widgetId);

  return {
    widgetId,
    state: SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE[role],
    path: appDefinition?.urlOverride!,
    vfr: appDefinition?.visibleForRoles ?? [],
    home: appDefinition?.socialHome,
    private: !appDefinition?.social,
  };
};

export const buildRoutesFromSlots = async (
  editorSDK: FlowEditorSDK,
  slots: WidgetSlot[],
) => {
  const applications = await getMembersAreaApplicationsFromSlots(slots);

  const integrationApplications: IntegrationApplication[] =
    (await getAppDefinitions({ editorSDK, applications })) ?? [];

  const appDefinitions = [MY_ACCOUNT_DEFINITION, ...integrationApplications];

  return slots.map<RouteConfiguration>(({ pluginInfo, role }) =>
    constructRoute({ appDefinitions, role, pluginInfo }),
  );
};
