import {
  getAppDefIdFromWidgetId,
  getPageIdFromWidgetId,
  InstallLocation,
  WidgetId,
} from '@wix/members-area-app-definitions';
import { getAppDefinition } from '@wix/members-area-app-definitions/get-app-definitions';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { MY_ACCOUNT_DEFINITION, SlugPlaceholder } from '../../../constants';
import { getPageRefByTPAPageId } from '../../../editor/services/page';
import type { RouteConfiguration } from '../../../types';
import {
  getProfilePageId,
  getProfilePageUriSEO,
  getSettingsPageId,
  getSettingsPageUriSEO,
} from './page';
import { getProfilePageRoutes, getSettingsPageRoutes } from './routes';

type NavigateToInnerPageProps = {
  editorSDK: FlowEditorSDK;
  routerId: string;
  innerRoute: string;
};

const logMissingRouteError = (widgetId: WidgetId) => {
  throw new Error(`[MA V3]: Could not find route for widgetId: ${widgetId}`);
};

const findRouteByWidgetId = (
  routes: RouteConfiguration[],
  widgetId: WidgetId,
) => {
  return routes.find((route) => route.widgetId === widgetId);
};

const navigateByPageId = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
) => {
  const tpaPageId = getPageIdFromWidgetId(widgetId);

  if (!tpaPageId) {
    throw new Error(
      `[MA V3]: Could not find tpaPageId for widgetId: ${widgetId}`,
    );
  }

  const pageRef = await getPageRefByTPAPageId(editorSDK, tpaPageId);

  return editorSDK.pages.navigateTo('', { pageRef });
};

const navigateToInnerPageRoute = ({
  editorSDK,
  routerId,
  innerRoute,
}: NavigateToInnerPageProps) => {
  return editorSDK.pages.navigateTo('', {
    pageLink: {
      innerRoute,
      routerId,
      type: 'DynamicPageLink',
      // @ts-expect-error missing type from platform
      isTpaRoute: true,
    },
  });
};

const navigateToProfilePageSection = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
) => {
  const profilePageRoutes = await getProfilePageRoutes(editorSDK);
  const route = findRouteByWidgetId(profilePageRoutes, widgetId);

  if (!route) {
    logMissingRouteError(widgetId);
  }

  const membersAreaPageId = await getProfilePageId(editorSDK);
  const suffix = await getProfilePageUriSEO(editorSDK);

  return navigateToInnerPageRoute({
    editorSDK,
    routerId: membersAreaPageId,
    innerRoute: `./${suffix}/${SlugPlaceholder.My}/${route!.path}`,
  });
};

export const navigateToSettingsPageSection = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
) => {
  const settingsPageRoutes = await getSettingsPageRoutes(editorSDK);
  const route = findRouteByWidgetId(settingsPageRoutes, widgetId);

  if (!route) {
    logMissingRouteError(widgetId);
  }

  const settingsPageId = await getSettingsPageId(editorSDK);
  const suffix = await getSettingsPageUriSEO(editorSDK);

  return navigateToInnerPageRoute({
    editorSDK,
    routerId: settingsPageId,
    innerRoute: `./${suffix}/${SlugPlaceholder.My}/${route!.path}`,
  });
};

const getAppDefinitionByWidgetId = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
) => {
  if (widgetId === WidgetId.MyAccount) {
    return MY_ACCOUNT_DEFINITION;
  }

  const appDefinitionId = getAppDefIdFromWidgetId(widgetId);

  return getAppDefinition({
    editorSDK,
    application: { widgetId, appDefinitionId },
  });
};

export const navigateToSection = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
) => {
  const definition = await getAppDefinitionByWidgetId(editorSDK, widgetId);

  switch (definition.installLocation) {
    case InstallLocation.Standalone:
      return navigateByPageId(editorSDK, widgetId);
    case InstallLocation.Profile:
      return navigateToProfilePageSection(editorSDK, widgetId);
    case InstallLocation.Settings:
      return navigateToSettingsPageSection(editorSDK, widgetId);
    default:
      return logMissingRouteError(widgetId);
  }
};
