import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import type { SettingsLayout } from '../../../constants';
import { getSettingsPageWidgetRef } from '../../../editor/services/page-ref';

export const getSettingsPageLayoutPreset = async (editorSDK: FlowEditorSDK) => {
  const componentRef = await getSettingsPageWidgetRef(editorSDK);
  if (!componentRef) {
    return null;
  }

  const { appStudioWidgets } = editorSDK.document.application;
  const layoutPreset = await appStudioWidgets.getPreset('', { componentRef });
  return layoutPreset.layout as SettingsLayout;
};

export const setSettingsPageLayoutPreset = async (
  editorSDK: FlowEditorSDK,
  layoutPreset: SettingsLayout,
) => {
  const componentRef = await getSettingsPageWidgetRef(editorSDK);
  if (!componentRef) {
    return;
  }

  return editorSDK.document.application.appStudioWidgets.changePreset('', {
    componentRef,
    layoutPresetId: layoutPreset,
    stylePresetId: layoutPreset,
  });
};
