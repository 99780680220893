import type { TpaPageLink } from '@wix/document-services-types';
import type { WidgetId } from '@wix/members-area-app-definitions';

import type { EditorMenuItem, MenuData } from '../../types';

export const getMenuItemsWithReplacedMembersPath = (
  items: EditorMenuItem[],
  currentPageUriSEO: string,
  newPageUriSEO: string,
): EditorMenuItem[] => {
  return items.map((menuItem) => {
    if (!menuItem.link?.path) {
      return menuItem;
    }

    const linkWithUpdatedPath: TpaPageLink = {
      ...menuItem.link,
      path: menuItem.link.path.replace(currentPageUriSEO, newPageUriSEO),
    };

    return {
      ...menuItem,
      link: linkWithUpdatedPath,
    };
  });
};

export const filterOutMenuItemsByIds = (
  menuItems: EditorMenuItem[],
  widgetsIds: WidgetId[],
) => {
  return menuItems.filter((menuItem) => {
    const itemId = (menuItem?.link?.itemId ?? '') as WidgetId;
    return !widgetsIds.includes(itemId);
  });
};

export const getMenuDataWithUpdatedItems = (
  menuData: MenuData,
  updatedItems: EditorMenuItem[],
) => ({ ...menuData, items: updatedItems });
