import { MEMBERS_AREA } from '@wix/app-definition-ids';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import type { MembersEditorScriptPublicAPI } from '../../types';

export const getMembersAreaUmbrellaPublicAPI = async (
  editorSDK: FlowEditorSDK,
) => {
  const api = await editorSDK.document.application.getPublicAPI('', {
    appDefinitionId: MEMBERS_AREA,
  });

  if (!api) {
    throw new Error(`Failed to get public API for ${MEMBERS_AREA}, in MA V2`);
  }

  return api as MembersEditorScriptPublicAPI;
};
