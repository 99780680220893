import type { EditorPlatformApp } from '../../types/editor';
import { createPrivateAPI } from './api-exports/private-api';
import { createPublicAPI } from './api-exports/public-api';
import { createAppManifest } from './app-manifest';
import { monitoredEditorReady } from './editor-ready';

export const singlePageApp: EditorPlatformApp = {
  editorReady: monitoredEditorReady,
  getAppManifest: createAppManifest,
  exports: {
    public: createPublicAPI,
    private: createPrivateAPI,
  },
};
