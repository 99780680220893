import type { WidgetId } from '@wix/members-area-app-definitions';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { MenuId } from '../../constants';
import { type MenuData } from '../../types';
import { getMenu, updateMenu } from '../editor-sdk-wrappers';
import {
  filterOutMenuItemsByIds,
  getMenuDataWithUpdatedItems,
  getMenuItemsWithReplacedMembersPath,
} from './utils';

export const getLoginBar = (editorSDK: FlowEditorSDK) => {
  return getMenu(editorSDK, MenuId.LoginMenu);
};

export const updateLoginBarMenu = (
  editorSDK: FlowEditorSDK,
  menuData: MenuData,
) => {
  return updateMenu(editorSDK, MenuId.LoginMenu, menuData);
};

export const removeLoginBarItems = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  const loginBarMenu = await getLoginBar(editorSDK);

  const filteredLoginBarMenuItems = filterOutMenuItemsByIds(
    loginBarMenu.items,
    widgetsIds,
  );

  const menuWithUpdatedItems = getMenuDataWithUpdatedItems(
    loginBarMenu,
    filteredLoginBarMenuItems,
  );

  return updateLoginBarMenu(editorSDK, menuWithUpdatedItems);
};

export const updateLoginBarLabel = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
  newLabel: string,
) => {
  const loginBarMenu = await getLoginBar(editorSDK);

  const updatedLoginBarMenuItems = loginBarMenu.items?.map((menuItem) => {
    return menuItem?.link?.itemId === widgetId
      ? { ...menuItem, label: newLabel }
      : menuItem;
  });

  const menuWithUpdatedItems = getMenuDataWithUpdatedItems(
    loginBarMenu,
    updatedLoginBarMenuItems,
  );

  return updateLoginBarMenu(editorSDK, menuWithUpdatedItems);
};

export const updateLoginBarMembersPath = async (
  editorSDK: FlowEditorSDK,
  currentPageUriSEO: string,
  newPageUriSEO: string,
) => {
  const loginBarMenu = await getLoginBar(editorSDK);

  const updatedLoginBarMenuItems = getMenuItemsWithReplacedMembersPath(
    loginBarMenu.items,
    currentPageUriSEO,
    newPageUriSEO,
  );

  const menuWithUpdatedItems = getMenuDataWithUpdatedItems(
    loginBarMenu,
    updatedLoginBarMenuItems,
  );

  return updateLoginBarMenu(editorSDK, menuWithUpdatedItems);
};
