import type { WidgetId } from '@wix/members-area-app-definitions';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { MenuId } from '../../../constants';
import { removeMenuItemsByWidgetIds } from './menu';

export const removeLoginBarIconsMenuItems = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  return removeMenuItemsByWidgetIds(
    editorSDK,
    MenuId.LoginIconsMenu,
    widgetsIds,
  );
};
