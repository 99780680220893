import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import type { ReferralInfo } from '../../constants';
import { openDashboardPanel, refreshApp } from '../editor-sdk-wrappers';

const MEMBER_PRIVACY_SETTINGS_RELATIVE_URL =
  '/member-privacy-settings/profile-privacy';

const getMemberSettingsUrl = (referralInfo?: ReferralInfo) => {
  if (!referralInfo) {
    return MEMBER_PRIVACY_SETTINGS_RELATIVE_URL;
  }

  return `${MEMBER_PRIVACY_SETTINGS_RELATIVE_URL}?referralInfo=${referralInfo}`;
};

export const openMemberSettingsBM = (
  editorSDK: FlowEditorSDK,
  referralInfo?: ReferralInfo,
) => {
  const URL = getMemberSettingsUrl(referralInfo);
  return openDashboardPanel(editorSDK, URL).then(() => refreshApp(editorSDK));
};
