import type { ExportsFn } from '@wix/platform-editor-sdk';

import { createEditorEventsHandlers } from './editor-events';
import { createPrivateAPI } from './private-api';
import { createPublicAPI } from './public-api';

export const exportsFn: ExportsFn = (editorSDK, contextParams) => {
  return {
    public: createPublicAPI(editorSDK, contextParams),
    private: createPrivateAPI(editorSDK, contextParams),
    editor: createEditorEventsHandlers(editorSDK),
  };
};
