import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import type { HistoryLabel } from '../../constants';

const addToHistory = async (editorSDK: FlowEditorSDK, label: string) =>
  editorSDK.history.add('', {
    label,
  });

export const withHistoryFactory =
  (editorSDK: FlowEditorSDK) =>
  async <T>(label: HistoryLabel, action: () => Promise<T>) => {
    const result = await action();
    await addToHistory(editorSDK, label);
    return result;
  };
