import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { isMemberPageInstalled } from '../../editor/services/page';
import { isProfilePageBobWidgetInstalled } from '../../editor/services/page-ref';

export const isProfilePageBobValid = async (editorSDK: FlowEditorSDK) => {
  try {
    return (
      (await isMemberPageInstalled(editorSDK)) &&
      (await isProfilePageBobWidgetInstalled(editorSDK))
    );
  } catch (e) {
    return false;
  }
};
