import { WidgetId } from '@wix/members-area-app-definitions';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { PanelOrigin } from '../../../constants';
import type {
  EditorMenuItem,
  ManageAndNavigatePanelInitialData,
  RouteConfiguration,
  SortableListItem,
} from '../../../types';
import { isCurrentLanguageOriginal } from '../../../utils';
import {
  getRouteByWidgetId,
  logManageAndNavigateMissingDataWarning,
} from '../../common/utils';
import { getProfilePageSubMenu } from '../services/profile-page-sub-menu';
import {
  getProfilePageRoutes,
  getSettingsPageRoutes,
} from '../services/routes';
import { getSettingsPageSubMenu } from '../services/settings-page-sub-menu';

const NOT_REMOVABLE_WIDGETS = [
  WidgetId.MyAccount,
  WidgetId.Notifications,
  WidgetId.Settings,
  WidgetId.About,
];

const createSortableListItem = (widgetId: WidgetId, label: string) => ({
  id: widgetId,
  label,
  selected: false,
  removable: !NOT_REMOVABLE_WIDGETS.includes(widgetId),
});

const toSortableListItems = (
  routes: RouteConfiguration[],
  menuItems: EditorMenuItem[],
): SortableListItem[] => {
  let shouldLogDataWarning = false;

  const panelInitialData = menuItems.reduce<SortableListItem[]>(
    (acc, menuItem) => {
      const widgetIdFromMenuItem = menuItem?.link?.itemId as WidgetId;
      const routeItem = getRouteByWidgetId(widgetIdFromMenuItem, routes);
      const widgetId = widgetIdFromMenuItem ?? routeItem?.widgetId;

      if (!routeItem || !widgetId) {
        shouldLogDataWarning = true;
      }

      if (!widgetId) {
        return acc;
      }

      acc.push(createSortableListItem(widgetId, menuItem.label));
      return acc;
    },
    [],
  );

  if (shouldLogDataWarning) {
    logManageAndNavigateMissingDataWarning({ routes, menuItems });
  }

  return panelInitialData;
};

const getFollowersTabsData = (
  routes: RouteConfiguration[],
): SortableListItem | null => {
  const followersRoute = routes.find(
    (route) => route.widgetId === WidgetId.FollowingFollowers,
  );

  if (!followersRoute) {
    return null;
  }

  return {
    id: followersRoute.widgetId,
    label: 'app.settings.manage.menu.panel.followers.title',
    selected: false,
    draggable: false,
    disableEditing: true,
  };
};

export const getProfilePageManageAndNavigateInitialData = async (
  editorSDK: FlowEditorSDK,
): Promise<ManageAndNavigatePanelInitialData> => {
  const [routes, subMenu, isOriginalLanguage] = await Promise.all([
    getProfilePageRoutes(editorSDK),
    getProfilePageSubMenu(editorSDK),
    isCurrentLanguageOriginal(editorSDK),
  ]);

  const items = toSortableListItems(routes, subMenu.items);
  const followersItem = getFollowersTabsData(routes);

  return {
    items,
    followersItem,
    isOriginalLanguage,
    panelOrigin: PanelOrigin.ProfilePage,
  };
};

export const getSettingsPageManageAndNavigateInitialData = async (
  editorSDK: FlowEditorSDK,
): Promise<ManageAndNavigatePanelInitialData> => {
  const [routes, subMenu, isOriginalLanguage] = await Promise.all([
    getSettingsPageRoutes(editorSDK),
    getSettingsPageSubMenu(editorSDK),
    isCurrentLanguageOriginal(editorSDK),
  ]);

  const items = toSortableListItems(routes, subMenu.items);

  return {
    items,
    isOriginalLanguage,
    panelOrigin: PanelOrigin.SettingsPage,
  };
};
