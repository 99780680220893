import type { WidgetId } from '@wix/members-area-app-definitions';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { Experiment, HistoryLabel, Layout } from '../../../constants';
import * as routes from '../../../editor/controller/controllers-routes';
import { updateRouteInConfig } from '../../../editor/controller/controllers-routes';
import {
  refreshApp,
  withHistoryFactory,
} from '../../../editor/editor-sdk-wrappers';
import { debouncedRefreshEditorRoutes } from '../../../editor/editor-sdk-wrappers/editor';
import {
  globalAppState,
  removeWidgets,
  setLayoutPreset,
} from '../../../editor/services';
import { getPublicApplications } from '../../../editor/services/applications';
import {
  hideProfileCard,
  hideProfileCardContainer,
  showProfileCard,
} from '../../../editor/services/layout';
import { installLightboxWidgets } from '../../../editor/services/lightbox';
import {
  monitoredTransactionFactory,
  toMonitored,
} from '../../../editor/services/monitor';
import { navigateToSection } from '../../../editor/services/navigation';
import {
  getMembersAreaPageRef,
  renameMembersAreaPage,
  updatePageUriSEO,
} from '../../../editor/services/page';
import {
  addWidgetsPlugins,
  getInstalledWidgetPlugins,
} from '../../../editor/services/slots';
import type { MembersPublicAPI, TFunction } from '../../../types';
import { populateGlobalControllerWithRoutes } from '../data-sanitizer/global-controller-fixer';
import { openManageAndNavigatePanel } from '../services/manage-and-navigate';
import { isProfilePageBobValid } from '../utils';

const initPublicAPI = (editorSDK: FlowEditorSDK): MembersPublicAPI => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);
  const withHistory = withHistoryFactory(editorSDK);

  return {
    getMembersAreaPageRef: () =>
      toMonitored('public-api.get-members-area-page-ref', () =>
        getMembersAreaPageRef(editorSDK),
      ),
    getInstalledWidgetPlugins: () =>
      toMonitored('public-api.get-installed-widget-plugins', () =>
        getInstalledWidgetPlugins(editorSDK),
      ),
    addLightboxes: (lightboxes) => {
      const flowAPI = globalAppState.getFlowAPI();
      const isResponsive = flowAPI?.environment.isEditorX ?? false;
      const action = () => {
        return installLightboxWidgets(editorSDK, lightboxes, isResponsive);
      };

      return monitoredTransaction('public-api.add-lightboxes', action);
    },
    addWidgetsPlugins: (integrationApplications, shouldNavigate) =>
      monitoredTransaction('public-api.add-widgets-plugins', () =>
        withHistory(HistoryLabel.WidgetsAdded, async () => {
          await addWidgetsPlugins({
            editorSDK,
            shouldNavigate,
            integrationApplications,
          });

          const flowAPI = globalAppState.getFlowAPI();
          const isRefreshRoutesEnabled =
            globalAppState.getIsRefreshRoutesEnabled();

          if (
            flowAPI?.experiments.enabled(Experiment.EnableSausageBar) &&
            isRefreshRoutesEnabled
          ) {
            return debouncedRefreshEditorRoutes(editorSDK);
          }

          globalAppState.enableRoutesRefresh();
        }),
      ),
    getPublicApplications: () => getPublicApplications(editorSDK),
    getRoutes: () =>
      toMonitored('public-api.get-routes', () => routes.getRoutes(editorSDK)),
    updateRoute: (payload) =>
      monitoredTransaction('public-api.update-route', () =>
        withHistory(HistoryLabel.RouteUpdated, () =>
          updateRouteInConfig(editorSDK, payload),
        ),
      ),
    removeWidgets: (widgetsIds) =>
      monitoredTransaction('public-api.remove-widgets', () =>
        withHistory(HistoryLabel.WidgetsDeleted, async () => {
          const flowAPI = globalAppState.getFlowAPI();

          await removeWidgets(editorSDK, widgetsIds);

          if (flowAPI?.experiments.enabled(Experiment.EnableSausageBar)) {
            return debouncedRefreshEditorRoutes(editorSDK);
          }
        }),
      ),
    renameMembersAreaPage: (newPageTitle) =>
      monitoredTransaction('public-api.rename-members-area-page', () =>
        renameMembersAreaPage(editorSDK, newPageTitle),
      ),
    updatePageUriSEO: (pageUriSEO) =>
      monitoredTransaction('public-api.update-page-uri-seo', () =>
        withHistory(HistoryLabel.PageUriSEOUpdated, () =>
          updatePageUriSEO(editorSDK, pageUriSEO),
        ),
      ),
    showProfileCard: () =>
      monitoredTransaction('public-api.show-profile-card', () =>
        showProfileCard(editorSDK),
      ),
    hideProfileCard: () =>
      monitoredTransaction('public-api.hide-profile-card', () =>
        hideProfileCard(editorSDK),
      ),
    hideProfileCardContainer: () =>
      monitoredTransaction('public-api.hide-profile-card-container', () =>
        hideProfileCardContainer(editorSDK),
      ),
    uninstall: () =>
      monitoredTransaction(
        'public-api.uninstall#editorSDK.application.uninstall',
        () => {
          return editorSDK.application.uninstall('', {
            openConfirmation: false,
          });
        },
      ),
    refreshApp: () =>
      monitoredTransaction('public-api.refresh-app', () =>
        refreshApp(editorSDK),
      ),
    /** @deprecated Routes are stored in the Umbrella application */
    populateGlobalControllerWithRoutes: () =>
      monitoredTransaction(
        'public-api.populate-global-controller-with-routes',
        () => populateGlobalControllerWithRoutes(editorSDK),
      ),
    isProfilePageBobValid: () => isProfilePageBobValid(editorSDK),
    navigateToSection: async (widgetId: WidgetId) =>
      navigateToSection(editorSDK, widgetId),
    openManageAndNavigatePanel: async () => {
      const action = () => {
        const flowAPI = globalAppState.getFlowAPI();
        if (!flowAPI) {
          throw new Error(
            '[Profile Page BoB - Public API] Flow API is missing',
          );
        }
        return openManageAndNavigatePanel(
          editorSDK,
          flowAPI.translations.t as TFunction,
        );
      };
      monitoredTransaction('public-api.open-manage-and-navigate-panel', action);
    },
    // To do: remove after templates migration
    setSidebarLayout: () => {
      return setLayoutPreset(editorSDK, Layout.Vertical);
    },
  };
};

export const createPublicAPI = (editorSDK: FlowEditorSDK): MembersPublicAPI => {
  const getPublicAPI = (() => {
    let _publicAPI: MembersPublicAPI;
    return async () => {
      if (!_publicAPI) {
        _publicAPI = initPublicAPI(editorSDK);
      }

      if (!(await _publicAPI.isProfilePageBobValid())) {
        throw new Error(
          '[Profile Page BoB - Public API] Member Page or Widget is missing',
        );
      }

      return _publicAPI;
    };
  })();

  return {
    getMembersAreaPageRef: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMembersAreaPageRef();
    },
    getInstalledWidgetPlugins: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getInstalledWidgetPlugins();
    },
    addLightboxes: async (lightboxes) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addLightboxes(lightboxes);
    },
    addWidgetsPlugins: async (integrationApplication, shouldNavigate) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addWidgetsPlugins(
        integrationApplication,
        shouldNavigate,
      );
    },
    getPublicApplications: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getPublicApplications();
    },
    getRoutes: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getRoutes();
    },
    updateRoute: async (payload) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.updateRoute(payload);
    },
    navigateToSection: async (payload) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.navigateToSection(payload);
    },
    removeWidgets: async (widgetsIds) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.removeWidgets(widgetsIds);
    },
    renameMembersAreaPage: async (newPageTitle) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.renameMembersAreaPage(newPageTitle);
    },
    updatePageUriSEO: async (pageUriSEO) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.updatePageUriSEO(pageUriSEO);
    },
    showProfileCard: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.showProfileCard();
    },
    hideProfileCard: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.hideProfileCard();
    },
    hideProfileCardContainer: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.hideProfileCardContainer();
    },
    uninstall: async () => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.uninstall();
    },
    refreshApp: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshApp();
    },
    /** @deprecated Routes are stored in the Umbrella application */
    populateGlobalControllerWithRoutes: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.populateGlobalControllerWithRoutes();
    },
    isProfilePageBobValid: async () => {
      const publicAPI = initPublicAPI(editorSDK);
      return publicAPI.isProfilePageBobValid();
    },
    openManageAndNavigatePanel: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.openManageAndNavigatePanel();
    },
    /** @deprecated This method is only for V2 and templates migration */
    setSidebarLayout: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.setSidebarLayout();
    },
  };
};
