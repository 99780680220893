import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { MY_ACCOUNT_DEFINITION } from '../../../constants';
import {
  toMonitored,
  transactionWithConflictMonitor,
} from '../../../editor/services/monitor';
import { getSettingsPageWidgetRef } from '../../../editor/services/page-ref';
import { waitForResult } from '../../../editor/services/retry-utils';
import { getWidgetSlots } from '../../../editor/services/slots';
import { updateSettingsPageRoutes } from './routes';
import { addWidgetPluginToSettingsPage } from './slots';

const getWidgetSlotsWithRetry = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
) => {
  try {
    const result = await waitForResult(
      async () => {
        const slots = await getWidgetSlots(editorSDK, widgetRef);
        return slots.length ? slots : undefined;
      },
      'ma-split.getWidgetSlotsForMyAccount',
      20,
      400,
    );
    return result;
  } catch (e: any) {
    if (e.message.includes('waitFor timed out')) {
      return [];
    }
    throw e;
  }
};

export const addMyAccountWidget = async (editorSDK: FlowEditorSDK) => {
  const widgetRef = await toMonitored(
    'ma-split.install.add-my-account-widget.getSettingsPageWidgetRef',
    () => getSettingsPageWidgetRef(editorSDK),
  );
  const widgetSlots = await toMonitored(
    'ma-split.install.add-my-account-widget.getWidgetSlots',
    () => getWidgetSlotsWithRetry(editorSDK, widgetRef),
  );
  const emptySlot = widgetSlots.find(({ pluginInfo }) => !pluginInfo);

  if (!emptySlot) {
    throw new Error('No empty slot was found for installing My Account widget');
  }

  const config = await toMonitored(
    'ma-split.install.add-my-account-widget.addWidgetPlugin',
    () => {
      const addMyAccountAction = () => {
        return addWidgetPluginToSettingsPage(
          editorSDK,
          MY_ACCOUNT_DEFINITION,
          emptySlot,
        );
      };

      return transactionWithConflictMonitor(
        editorSDK,
        'ma-split.add-my-account-widget',
        addMyAccountAction,
      );
    },
  );

  return toMonitored(
    'ma-split.add-my-account-widget.createRouteConfigurations',
    () => updateSettingsPageRoutes(editorSDK, [config]),
  );
};
