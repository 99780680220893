import type { TranslateFunc } from '@wix/bob-widget-services';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import type { FlowEditorSDK, GetAppManifestFn } from '@wix/yoshi-flow-editor';

import { withRetry } from '../../editor/services';
import { toMonitored } from '../../editor/services/monitor';

type FetchWidgetsStageDataOptions = {
  initialAppData: any; // issue on EP: https://jira.wixpress.com/browse/EP-4024
  editorSDK: FlowEditorSDK;
  translateFunc: TranslateFunc;
};

const monitoredFetchWidgetsStageData = (
  options: FetchWidgetsStageDataOptions,
) =>
  toMonitored('getAppManifest.fetchWidgetsStageData', () =>
    withRetry(() => fetchWidgetsStageData(options), {
      methodName: 'fetchWidgetsStageData',
      retryCount: 20,
      retryDelay: 400,
    }),
  );

export const createAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  _contextParams,
  flowAPI,
) => {
  const { appManifestBuilder } = options;
  const { t } = flowAPI.translations;

  const rawManifest = await monitoredFetchWidgetsStageData({
    initialAppData: options,
    editorSDK,
    translateFunc: t,
  });

  return appManifestBuilder.withJsonManifest(rawManifest).build();
};
