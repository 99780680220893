import type { addWidgetOptions } from '@wix/platform-editor-sdk';

export const OLD_EDITOR_X_WIDGET_LAYOUT: addWidgetOptions['layouts'] = {
  itemLayout: {
    id: '',
    alignSelf: 'start',
    justifySelf: 'center',
    type: 'GridItemLayout',
    gridArea: {
      rowStart: 2,
      rowEnd: 3,
      columnStart: 1,
      columnEnd: 2,
    },
  },
  componentLayout: {
    type: 'ComponentLayout',
    maxWidth: {
      type: 'px',
      value: 980,
    },
    width: {
      type: 'percentage',
      value: 100,
    },
    height: {
      type: 'auto',
    },
  },
};

export const EDITOR_X_WIDGET_LAYOUT: addWidgetOptions['layouts'] = {
  itemLayout: {
    id: '',
    alignSelf: 'start',
    justifySelf: 'center',
    type: 'GridItemLayout',
    gridArea: {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 1,
      columnEnd: 2,
    },
  },
  componentLayout: {
    type: 'ComponentLayout',
    maxWidth: {
      type: 'px',
      value: 980,
    },
    width: {
      type: 'percentage',
      value: 100,
    },
    height: {
      type: 'auto',
    },
  },
};

export const EDITOR_X_LIGHTBOX_LAYOUT: addWidgetOptions['layouts'] = {
  containerLayout: {
    type: 'GridContainerLayout',
    rows: [
      {
        type: 'fr',
        value: 1,
      },
    ],
    columns: [
      {
        type: 'fr',
        value: 1,
      },
    ],
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  /** @ts-expect-error */
  spx: {
    refWidth: 1200,
    resolverType: 'scale',
  },
  componentLayout: {
    hidden: false,
    height: {
      type: 'percentage',
      value: 100,
    },
    type: 'ComponentLayout',
    width: {
      type: 'percentage',
      value: 100,
    },
    maxWidth: {
      type: 'px',
      value: 700,
    },
  },
  itemLayout: {
    id: '',
    type: 'FixedItemLayout',
    margins: {
      left: {
        type: 'px',
        value: 0,
      },
      right: {
        type: 'px',
        value: 0,
      },
      top: {
        type: 'px',
        value: 0,
      },
      bottom: {
        type: 'px',
        value: 0,
      },
    },
    justifySelf: 'center',
    alignSelf: 'center',
  },
  type: 'SingleLayoutData',
};

export const EDITOR_X_LIGHTBOX_WIDGET_LAYOUT: addWidgetOptions['layouts'] = {
  /** @ts-expect-error */
  containerLayout: {},
  spx: {
    refWidth: 1200,
    resolverType: 'scale',
  },
  componentLayout: {
    hidden: false,
    height: {
      type: 'auto',
    },
    type: 'ComponentLayout',
    width: {
      type: 'percentage',
      value: 100,
    },
  },
  itemLayout: {
    id: '',
    alignSelf: 'center',
    margins: {
      left: {
        type: 'percentage',
        value: 0,
      },
      right: {
        type: 'percentage',
        value: 0,
      },
      top: {
        type: 'percentage',
        value: 0,
      },
      bottom: {
        type: 'percentage',
        value: 0,
      },
    },
    gridArea: {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 1,
      columnEnd: 2,
    },
    justifySelf: 'center',
    type: 'GridItemLayout',
  },
  type: 'SingleLayoutData',
};
