import type { WidgetId } from '@wix/members-area-app-definitions';
import { getAppDefIdFromWidgetId } from '@wix/members-area-app-definitions';
import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { log } from '../../../editor/services/monitor';
import {
  getEmptySlots,
  removeWidgetsPlugins,
} from '../../../editor/services/slots';
import type { CommonSyncDataProps, WidgetPluginPointer } from '../../../types';
import { determineSyncOperations } from './sync-utils';

type SyncPluginsWithMenuItems = CommonSyncDataProps & {
  widgetIdsFromSlots: WidgetId[];
};

const addWidgetPlugin = (
  editorSDK: FlowEditorSDK,
  widgetPluginPointer: WidgetPluginPointer,
  slotCompRef: ComponentRef,
) => {
  return editorSDK.tpa.widgetPlugins.addWidgetPlugin('', {
    widgetPluginPointer,
    slotCompRef,
  });
};

const addWidgetPlugins = async (
  editorSDK: FlowEditorSDK,
  widgetIdsToAdd: WidgetId[],
) => {
  const emptySlots = await getEmptySlots(editorSDK);

  if (!emptySlots.length) {
    return;
  }

  for (const widgetId of widgetIdsToAdd) {
    const slot = emptySlots.shift();

    if (slot) {
      await addWidgetPlugin(
        editorSDK,
        { widgetId, appDefinitionId: getAppDefIdFromWidgetId(widgetId) },
        slot.compRef,
      );
    }
  }
};

export const syncPlugins = async ({
  editorSDK,
  widgetIdsFromSlots,
  menuBasedWidgetIds,
}: SyncPluginsWithMenuItems) => {
  const { idsToAdd, idsToRemove } = determineSyncOperations({
    sourceOfTruth: menuBasedWidgetIds,
    syncTarget: widgetIdsFromSlots,
  });

  if (idsToRemove.length) {
    await removeWidgetsPlugins(editorSDK, idsToRemove);
  }

  if (idsToAdd.length) {
    await addWidgetPlugins(editorSDK, idsToAdd);
  }

  if (idsToAdd.length || idsToRemove.length) {
    log('editorReady: Syncing plugins with menu items', {
      extra: {
        idsToAdd,
        idsToRemove,
        widgetIdsFromSlots,
        menuBasedWidgetIds,
      },
    });
  }
};
