import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { debounce } from 'lodash';

import type { PrivateAPI } from '../../types';

const REFRESH_DEBOUNCE_TIME = 6000;

export const getPrivateAPI = async (
  editorSDK: FlowEditorSDK,
): Promise<PrivateAPI> => {
  const api = await editorSDK.editor.getAppAPI();

  if (!api) {
    throw new Error(`Failed to get private API for Profile Page Bob`);
  }

  return api;
};

const refreshEditorRoutes = (editorSDK: FlowEditorSDK) => {
  return editorSDK.editor.routers.refresh('');
};

export const openDashboardPanel = async (
  editorSDK: FlowEditorSDK,
  url: string,
  closeOtherPanels: boolean = true,
) => {
  return editorSDK.editor.openDashboardPanel('', {
    url,
    closeOtherPanels,
  });
};

export const debouncedRefreshEditorRoutes = debounce(
  (editorSDK: FlowEditorSDK): Promise<void> => refreshEditorRoutes(editorSDK),
  REFRESH_DEBOUNCE_TIME,
);
