import { WidgetId } from '@wix/members-area-app-definitions';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { PanelOrigin } from '../../../../constants';
import { getRoutes } from '../../../../editor/controller/controllers-routes';
import { getMembersSubMenu } from '../../../../editor/services/members-sub-menu';
import type {
  EditorMenuItem,
  ManageAndNavigatePanelInitialData,
  RouteConfiguration,
  SortableListItem,
} from '../../../../types';
import { isCurrentLanguageOriginal } from '../../../../utils';
import {
  getRouteByWidgetId,
  logManageAndNavigateMissingDataWarning,
} from '../../../common/utils';

const NOT_REMOVABLE_WIDGETS = [
  WidgetId.MyAccount,
  WidgetId.Notifications,
  WidgetId.Settings,
];

const getFollowersTabsData = (
  routes: RouteConfiguration[],
): SortableListItem | null => {
  const followersRoute = routes.find(
    (route) => route.widgetId === WidgetId.FollowingFollowers,
  );

  if (!followersRoute) {
    return null;
  }

  return {
    id: followersRoute.widgetId,
    label: 'app.settings.manage.menu.panel.followers.title',
    value: 'app.settings.manage.menu.panel.followers.value',
    selected: false,
    draggable: false,
    disableEditing: true,
  };
};

const createSortableListItem = (
  widgetId: WidgetId,
  label: string,
  isPrivate: boolean,
) => ({
  id: widgetId,
  label,
  value: isPrivate
    ? 'app.settings.manage.menu.panel.item.subtitle.private'
    : 'app.settings.manage.menu.panel.item.subtitle.public',
  selected: false,
  removable: !NOT_REMOVABLE_WIDGETS.includes(widgetId),
});

const toSortableListItems = (
  routes: RouteConfiguration[],
  menuItems: EditorMenuItem[],
): SortableListItem[] => {
  let shouldLogDataWarning = false;

  const panelInitialData = menuItems.reduce<SortableListItem[]>(
    (acc, menuItem) => {
      const widgetIdFromMenuItem = menuItem?.link?.itemId as WidgetId;
      const routeItem = getRouteByWidgetId(widgetIdFromMenuItem, routes);
      const widgetId = widgetIdFromMenuItem ?? routeItem?.widgetId;

      if (!routeItem || !widgetId) {
        shouldLogDataWarning = true;
      }

      if (!widgetId) {
        return acc;
      }

      acc.push(
        createSortableListItem(widgetId, menuItem.label, !!routeItem?.private),
      );
      return acc;
    },
    [],
  );

  if (shouldLogDataWarning) {
    logManageAndNavigateMissingDataWarning({ routes, menuItems });
  }

  return panelInitialData;
};

export const getManageAndNavigatePanelInitialData = async (
  editorSDK: FlowEditorSDK,
): Promise<ManageAndNavigatePanelInitialData> => {
  const [routes, subMenu, isOriginalLanguage] = await Promise.all([
    getRoutes(editorSDK),
    getMembersSubMenu(editorSDK),
    isCurrentLanguageOriginal(editorSDK),
  ]);

  const items = toSortableListItems(routes, subMenu.items);
  const followersItem = getFollowersTabsData(routes);

  return {
    items,
    followersItem,
    isOriginalLanguage,
    panelOrigin: PanelOrigin.SinglePageApp,
  };
};
