import { getAppDefIdFromWidgetId } from '@wix/members-area-app-definitions';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import type { WidgetPluginPointer } from '../../types';
import { getRoutes } from '../controller/controllers-routes';

export const getPublicApplications = async (editorSDK: FlowEditorSDK) => {
  const routes = await getRoutes(editorSDK);

  return routes.reduce<WidgetPluginPointer[]>((publicRoutes, route) => {
    if (route.private) {
      return publicRoutes;
    }
    const appDefinitionId = getAppDefIdFromWidgetId(route.widgetId);

    return [...publicRoutes, { appDefinitionId, widgetId: route.widgetId }];
  }, []);
};
