import type { WidgetId } from '@wix/members-area-app-definitions';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { MenuId } from '../../constants';
import type { MenuData } from '../../types';
import { getMenu, updateMenu } from '../editor-sdk-wrappers';
import {
  filterOutMenuItemsByIds,
  getMenuDataWithUpdatedItems,
  getMenuItemsWithReplacedMembersPath,
} from './utils';

const getLoginBarIconsMenu = (editorSDK: FlowEditorSDK) => {
  return getMenu(editorSDK, MenuId.LoginIconsMenu);
};

const updateLoginBarIconsMenu = (
  editorSDK: FlowEditorSDK,
  menuData: MenuData,
) => {
  return updateMenu(editorSDK, MenuId.LoginIconsMenu, menuData);
};

export const removeLoginBarIconsMenuItems = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  const loginBarIconsMenu = await getLoginBarIconsMenu(editorSDK);

  const filteredLoginBarIconsMenuItems = filterOutMenuItemsByIds(
    loginBarIconsMenu.items,
    widgetsIds,
  );

  const menuWithUpdatedItems = getMenuDataWithUpdatedItems(
    loginBarIconsMenu,
    filteredLoginBarIconsMenuItems,
  );

  return updateLoginBarIconsMenu(editorSDK, menuWithUpdatedItems);
};

export const updateLoginBarIconsMenuMembersPath = async (
  editorSDK: FlowEditorSDK,
  currentPageUriSEO: string,
  newPageUriSEO: string,
) => {
  const loginBarIconsMenu = await getLoginBarIconsMenu(editorSDK);

  const updatedLoginBarIconsMenuItems = getMenuItemsWithReplacedMembersPath(
    loginBarIconsMenu.items,
    currentPageUriSEO,
    newPageUriSEO,
  );

  const menuWithUpdatedItems = getMenuDataWithUpdatedItems(
    loginBarIconsMenu,
    updatedLoginBarIconsMenuItems,
  );

  return updateLoginBarIconsMenu(editorSDK, menuWithUpdatedItems);
};
