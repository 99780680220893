import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

import type { Nullable } from '../../types';

interface GlobalAppState {
  getFlowAPI: () => Nullable<EditorScriptFlowAPI>;
  setFlowAPI: (flowAPI: EditorScriptFlowAPI) => void;
  getIsClassicEditor: () => Nullable<boolean>;
  setIsClassicEditor: (IsClassicEditor: boolean) => void;
  getIsRefreshRoutesEnabled: () => boolean;
  enableRoutesRefresh: () => void;
  disableRoutesRefresh: () => void;
}

const initGlobalAppState = (): GlobalAppState => {
  let _flowAPI: Nullable<EditorScriptFlowAPI> = null;
  let _isClassicEditor: Nullable<boolean> = null;
  let _isRefreshRoutesEnabled = true;

  return {
    getFlowAPI: () => _flowAPI,
    setFlowAPI: (flowAPI) => (_flowAPI = flowAPI),
    getIsClassicEditor: () => _isClassicEditor,
    setIsClassicEditor: (isClassicEditor: boolean) =>
      (_isClassicEditor = isClassicEditor),
    getIsRefreshRoutesEnabled: () => _isRefreshRoutesEnabled,
    enableRoutesRefresh: () => (_isRefreshRoutesEnabled = true),
    disableRoutesRefresh: () => (_isRefreshRoutesEnabled = false),
  };
};

export const globalAppState = initGlobalAppState();
