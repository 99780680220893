import type {
  addWidgetOptions,
  Layout,
  PopupPageDefinition,
} from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import {
  FOLLOWING_FOLLOWERS_MODAL_WIDGET_ID,
  FOLLOWING_FOLLOWERS_MODAL_WIDGET_OPTIONS,
  LightboxConnectionParam,
  SANTA_MEMBERS_APP_DEF_ID,
} from '../../constants';
import {
  EDITOR_X_LIGHTBOX_LAYOUT,
  EDITOR_X_LIGHTBOX_WIDGET_LAYOUT,
} from '../../constants/editor-x';
import type { Lightbox } from '../../types';
import { addWidget } from '../editor-sdk-wrappers';

type CreateLightboxOptions = {
  width: number;
  height: number;
  isResponsive: boolean;
} & Lightbox;

const createPopupPageDefinition = ({
  appDefinitionId,
  widgetId,
  pageId,
  width,
  height,
  isResponsive,
}: Omit<CreateLightboxOptions, 'page'>): PopupPageDefinition => {
  return {
    data: {
      appDefinitionId,
      managingAppDefId: SANTA_MEMBERS_APP_DEF_ID,
      tpaPageId: pageId,
      pageBackgrounds: {
        desktop: {
          custom: true,
          isPreset: false,
          ref: {
            type: 'BackgroundMedia',
            color: '#000',
            /** @ts-expect-error */
            colorOpacity: 0.6,
            alignType: 'center',
            fittingType: 'fill',
            scrollType: 'none',
            colorOverlay: '',
            colorOverlayOpacity: 0,
          },
        },
        mobile: { custom: false, isPreset: false },
      },
    },
    components: [
      {
        type: 'Container',
        id: widgetId,
        skin: 'wysiwyg.viewer.skins.area.RectangleArea',
        layout: {
          width,
          height,
          x: 0,
          y: 0,
        },
        layouts: isResponsive ? EDITOR_X_LIGHTBOX_LAYOUT : undefined,
        componentType: 'wysiwyg.viewer.components.PopupContainer',
        props: {
          type: 'PopupContainerProperties',
        },
      },
    ],
    style: {
      type: 'ComponentStyle',
      style: {
        properties: {
          'alpha-bg': '0.6',
          bg: 'color_15',
        },
        propertiesSource: {
          'alpha-bg': 'value',
          bg: 'theme',
        },
        groups: {},
      },
      componentClassName: 'mobile.core.components.Page',
      styleType: 'custom',
      skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
      pageId: '',
      compId: '',
    },
  };
};

const createLightbox = async (
  editorSDK: FlowEditorSDK,
  options: CreateLightboxOptions,
) => {
  const {
    appDefinitionId,
    page,
    pageId,
    widgetId,
    width,
    height,
    isResponsive,
  } = options;

  const popupRef = await editorSDK.document.pages.popupPages.addConnected('', {
    title: page.name,
    shouldNavigateToPage: false,
    controllerType: `${LightboxConnectionParam.Controller}_${pageId}`,
    popupRole: `${LightboxConnectionParam.Role}_${pageId}`,
    definition: createPopupPageDefinition({
      appDefinitionId,
      widgetId,
      pageId,
      width,
      height,
      isResponsive,
    }),
  });

  editorSDK.document.pages.popupPages.updateBehavior('', {
    popupRef,
    behavior: {
      name: 'openPopup',
      params: {
        openInDesktop: false,
        openInMobile: false,
        delay: 2,
      },
    },
  });

  const [lightboxCompRef] = await editorSDK.document.components.getChildren(
    '',
    { componentRef: popupRef },
  );

  return lightboxCompRef ?? popupRef;
};

const lightboxWidgetOptionsMap: Record<
  string,
  addWidgetOptions & { layout: Layout }
> = {
  [FOLLOWING_FOLLOWERS_MODAL_WIDGET_ID]:
    FOLLOWING_FOLLOWERS_MODAL_WIDGET_OPTIONS,
};

const installLightboxWidget = async (
  editorSDK: FlowEditorSDK,
  lightbox: Lightbox,
  isResponsive: boolean,
) => {
  const lightboxWidgetOptions = lightboxWidgetOptionsMap[lightbox.widgetId];
  if (!lightboxWidgetOptions) {
    console.warn(
      `Members Area: Not supported lightbox widget ${lightbox.widgetId}`,
    );

    return;
  }

  const lightboxCompRef = await createLightbox(editorSDK, {
    ...lightbox,
    isResponsive,
    width: lightboxWidgetOptions.layout.width,
    height: lightboxWidgetOptions.layout?.height,
  });

  return addWidget(editorSDK, {
    ...lightboxWidgetOptions,
    containerRef: lightboxCompRef,
    layouts: isResponsive ? EDITOR_X_LIGHTBOX_WIDGET_LAYOUT : undefined,
  });
};

export const installLightboxWidgets = async (
  editorSDK: FlowEditorSDK,
  lighboxes: Lightbox[],
  isResponsive: boolean,
) => {
  const promises = lighboxes.map((lightbox) => {
    return installLightboxWidget(editorSDK, lightbox, isResponsive);
  });

  await Promise.all(promises);
};
