import type {
  IntegrationApplication,
  WidgetId,
} from '@wix/members-area-app-definitions';
import type { ExportsStatic, PageRef } from '@wix/platform-editor-sdk';

import type { PanelOrigin } from '../constants';
import type { RouteConfiguration, UpdateRoutePayload } from './route-service';
import type { Callback } from './util';
import type { WidgetPluginPointer } from './widget';

export enum MembersAreaContext {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}

type OpenAddTabsPanelProps = {
  panelOrigin: PanelOrigin;
  referralInfo?: string;
};

export type Lightbox = Pick<
  IntegrationApplication,
  'appDefinitionId' | 'widgetId' | 'pageId'
> & { page: { name: string } };

export type OpenUnifiedManagerPanelProps = {
  initialTab: 'pages' | 'memberPageTabs' | 'settingsPageTabs';
};

type UninstallSubAppProps = { widgetId: WidgetId };

export interface MembersPublicAPI extends ExportsStatic {
  getMembersAreaPageRef: () => Promise<PageRef>;
  getInstalledWidgetPlugins: () => Promise<WidgetPluginPointer[]>;
  addWidgetsPlugins: (
    integrationApplication: IntegrationApplication[],
    shouldNavigate?: boolean,
  ) => Promise<void>;
  removeWidgets: (widgetsIds: WidgetId[]) => Promise<void>;
  addLightboxes: (lightboxes: Lightbox[]) => Promise<void>;
  getPublicApplications: () => Promise<WidgetPluginPointer[]>;
  getRoutes: () => Promise<RouteConfiguration[]>;
  updateRoute: (payload: UpdateRoutePayload) => Promise<void>;
  renameMembersAreaPage: (newPageTitle: string) => Promise<void>;
  updatePageUriSEO: (pageUriSEO: string) => Promise<void>;
  showProfileCard: () => Promise<void>;
  hideProfileCard: () => Promise<void>;
  hideProfileCardContainer: () => Promise<void>;
  uninstall: () => Promise<void>;
  refreshApp: () => Promise<void>;
  /** @deprecated Routes are stored in the Umbrella application */
  populateGlobalControllerWithRoutes: () => Promise<void>;
  isProfilePageBobValid: () => Promise<boolean>;
  navigateToSection: (widgetId: WidgetId) => Promise<void>;
  openManageAndNavigatePanel: () => Promise<void>;
  setSidebarLayout: () => Promise<void>;
}

export interface ViewerPublicAPI {
  getViewedUser: (onSuccess?: Callback, onError?: Callback) => Promise<string>;
  enterPublicProfilePreviewMode: () => Promise<void>;
  leavePublicProfilePreviewMode: () => Promise<void>;
  openBlockedMemberEmptyState: () => Promise<void>;
  clearMenus: () => Promise<void>;
}

export interface MembersEditorScriptPublicAPI {
  openAddTabsPanel: (props: OpenAddTabsPanelProps) => Promise<void>;
  getRoutes: () => Promise<RouteConfiguration[]>;
  setRoutes: (routes: RouteConfiguration[]) => Promise<void>;
  updateRoute: (payload: UpdateRoutePayload) => Promise<void>;
  getSettingsRoutes: () => Promise<RouteConfiguration[]>;
  setSettingsRoutes: (routes: RouteConfiguration[]) => Promise<void>;
  getMembersAreaContext: () => Promise<MembersAreaContext>;
  openUnifiedManagerPanel: (
    props: OpenUnifiedManagerPanelProps,
  ) => Promise<void>;
  uninstallSubApp: (props: UninstallSubAppProps) => Promise<void>;
}
