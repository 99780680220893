import type { WidgetId } from '@wix/members-area-app-definitions';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { Experiment } from '../../constants';
import type { RouteConfiguration, UpdateRoutePayload } from '../../types';
import { refreshApp } from '../editor-sdk-wrappers';
import { globalAppState } from '../services';
import { getMembersAreaUmbrellaPublicAPI } from '../services/members-editor-script';
import { log } from '../services/monitor';
import { maybeNavigateToMemberPage } from '../services/navigation';
import {
  getRoutesFromGlobalController,
  updateGlobalControllerWithRoutes,
} from './global-controller';
import { updateMemberPageControllerWithRoutes } from './member-page-controller';

export const getRoutes = async (editorSDK: FlowEditorSDK) => {
  const flowAPI = globalAppState.getFlowAPI();
  if (flowAPI?.experiments.enabled(Experiment.UseAppDataForRoutes)) {
    const membersAreaPublicAPI = await getMembersAreaUmbrellaPublicAPI(
      editorSDK,
    );
    return membersAreaPublicAPI.getRoutes();
  }

  return getRoutesFromGlobalController(editorSDK);
};

export const getRoutesWidgetIds = async (editorSDK: FlowEditorSDK) => {
  const routes = await getRoutes(editorSDK);

  return routes.map(({ widgetId }) => widgetId);
};

export const updateRoutesConfig = async (
  editorSDK: FlowEditorSDK,
  updatedRoutes: RouteConfiguration[],
) => {
  const flowAPI = globalAppState.getFlowAPI();

  // TODO: Should we stop updating global controller?
  await updateGlobalControllerWithRoutes(editorSDK, updatedRoutes);

  if (flowAPI?.experiments.enabled(Experiment.UseAppDataForRoutes)) {
    const membersAreaPublicAPI = await getMembersAreaUmbrellaPublicAPI(
      editorSDK,
    );
    await membersAreaPublicAPI.setRoutes(updatedRoutes);
  }

  const avoidNavigation =
    globalAppState.getIsClassicEditor() ||
    flowAPI?.experiments.enabled(Experiment.RemoveUpdatingBoBController);

  if (avoidNavigation) {
    return;
  }

  // Updating BoB controller should be unecessary after global controller is available everywhere
  // Until then this is for fallback for pages without a header
  await maybeNavigateToMemberPage(editorSDK);

  updateMemberPageControllerWithRoutes(editorSDK, updatedRoutes).catch(
    (e: any) => {
      log('Warning: Failed to update BoB controller', {
        extra: {
          error: typeof e === 'string' ? e : (e as Error).message,
        },
      });
    },
  );
};

export const updateRouteInConfig = async (
  editorSDK: FlowEditorSDK,
  updatedRoute: UpdateRoutePayload,
) => {
  const routes = await getRoutes(editorSDK);

  if (!routes.length) {
    return;
  }

  const updatedRoutes = routes.map((route) => {
    return route.widgetId === updatedRoute.widgetId
      ? { ...route, ...updatedRoute }
      : route;
  });

  await updateRoutesConfig(editorSDK, updatedRoutes);
  await refreshApp(editorSDK);
};

export const removeRoutes = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  const routes = await getRoutes(editorSDK);
  if (!routes.length) {
    return;
  }

  const filteredRoutes = routes.filter(
    (route) => !widgetsIds.includes(route.widgetId),
  );

  return updateRoutesConfig(editorSDK, filteredRoutes);
};
